import * as React from "react"

import Navigation from 'components/ui/navigation/Navigation'


// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <Navigation />
      <p>
        404
      </p>
    </main>
  )
}

export default NotFoundPage
